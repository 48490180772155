export const AWS_SECRETS = {
  credentials: {
    accessKeyId: 'AKIATZLRADD47EJPHB4S',
    secretAccessKey: '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',
  },
};

export const DATADOG_SECRETS = {
  clientToken: 'pubae7f744fd5b2f935456126340094adb2',
};
