<div class="app-container column">
  <app-photo-test-app-explainer></app-photo-test-app-explainer>


  <div class="steps-container">
    <div class="step">
      <p class="step-heading">Step 1. Select a photo</p>

      <input type="file" #fileInput (change)="onFileSelected($event)" accept=".jpg, .png" style="display:none" />
      <div class="row">
        <button class="primary" (click)="fileInput.click()">Add photo</button>
        <p *ngIf="photoSelected">Photo successfully added</p>
      </div>

    </div>

    <div class="step">
      <p class="step-heading">Step 2. Click Run Test</p>
      <button class="upload" (click)="runTest()" [disabled]="!photoSelected">Run Test</button>
    </div>

    <div class="step">
      <p class="step-heading">Step 3. Test Results</p>
      <app-photo-upload-result *ngIf="uploadResults" [photoUploadResults]="uploadResults"></app-photo-upload-result>
    </div>
  </div>
</div>



<router-outlet></router-outlet>