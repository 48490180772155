import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { PhotoTestAppExplainerComponent } from './photo-test-app-explainer/photo-test-app-explainer.component';
import { AssetUpload } from '../services/asset-upload.service';
import { Environments, UploadAssetResult } from '../types';
import { PhotoUploadResultComponent } from './photo-upload-result/photo-upload-result.component';
import { Logger } from '../services/logger.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    PhotoTestAppExplainerComponent,
    PhotoUploadResultComponent,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  photoSelected: boolean = false;
  selectedFile: File | null = null;
  assetUploadService: AssetUpload | null = null;
  logger: Logger;
  uploadResults: UploadAssetResult[] | null = null;
  environment: Environments;

  constructor() {
    this.environment = this.getEnvironment();
    this.logger = new Logger(this.environment);
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;

    if (input.files && input.files[0]) {
      this.selectedFile = input.files[0];
      this.photoSelected = true;
    }
  }

  async runTest() {
    if (!this.photoSelected || this.selectedFile === null) {
      return;
    }

    this.assetUploadService = new AssetUpload(this.selectedFile, {
      env: this.environment,
    });

    this.uploadResults = await this.assetUploadService.uploadFile();

    this.uploadResults?.forEach((result) => {
      const successfulUpload = Boolean(result.data) && !Boolean(result.error);

      if (successfulUpload) {
        this.logger.info(`${result.testName} success`);
      } else {
        this.logger.error(`${result.testName} failed`, {
          reason: result.error,
        });
      }
    });
  }

  private getEnvironment(): Environments {
    const { hostname } = window.location;
    const [subdomain] = hostname.split('.');
    const [, , , rawEnvironment] = subdomain.split('-');

    if (hostname.startsWith('localhost') || rawEnvironment === 'dev') {
      return 'development';
    }

    if (!rawEnvironment) {
      return 'production';
    }

    return rawEnvironment as Environments;
  }
}
