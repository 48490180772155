import { datadogLogs } from '@datadog/browser-logs';
import { DATADOG_SECRETS } from '../config/secrets';
import { Environments } from '../types';

export class Logger {
  constructor(env: Environments) {
    datadogLogs.init({
      clientToken: DATADOG_SECRETS.clientToken,
      site: 'datadoghq.eu',
      forwardErrorsToLogs: true,
      service: this.getServiceName(env),
    });
  }

  info(msg: string, context = {}) {
    datadogLogs.logger.log(msg, { status: 'info', ...context });
  }

  warn(msg: string, context = {}) {
    datadogLogs.logger.log(msg, { status: 'warn', ...context });
  }

  error(msg: string, context = {}) {
    datadogLogs.logger.log(msg, { status: 'error', ...context });
  }

  private getServiceName(env: Environments) {
    const baseServiceName = 'photo-test-app-client';

    if (env === 'production') {
      return baseServiceName;
    }

    return `${baseServiceName}-${env}`;
  }
}
