<div class="explainer-container">
  <h1 class="margin-left-45">Photo Upload Test App</h1>

  <p class="margin-left-45">Thanks for participating in helping us to test various photo upload mechanisms! Before you
    begin, please read the
    instructions below:</p>

  <ol class="margin-left-25">
    <li>Ensure you are connected to WiFi without a VPN</li>
    <li>Select a photo from your computer to use for the test</li>
    <li>Click Run Test</li>
    <li>Test results will be displayed in the results report. Please take a screenshot or print the page as PDF and
      forward via email to the Sitemate team</li>
  </ol>
</div>
