import { Component, Input } from '@angular/core';
import { UploadAssetResult } from '../../types';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-photo-upload-result',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './photo-upload-result.component.html',
  styleUrl: './photo-upload-result.component.scss',
})
export class PhotoUploadResultComponent {
  @Input() photoUploadResults!: UploadAssetResult[];
}
