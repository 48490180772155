import { Component } from '@angular/core';

@Component({
  selector: 'app-photo-test-app-explainer',
  standalone: true,
  imports: [],
  templateUrl: './photo-test-app-explainer.component.html',
  styleUrl: './photo-test-app-explainer.component.scss',
})
export class PhotoTestAppExplainerComponent {}
