import { PutObjectCommand, S3Client, S3ClientConfig } from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { createPresignedPost } from '@aws-sdk/s3-presigned-post';

interface S3BucketConstructorParams {
  region: string;
  credentials: {
    accessKeyId: string;
    secretAccessKey: string;
  };
  s3ClientConfig?: Partial<S3ClientConfig>;
}

export class S3Service {
  client: S3Client;

  constructor(args: S3BucketConstructorParams) {
    this.client = new S3Client({
      region: args.region,
      forcePathStyle: true,
      useAccelerateEndpoint: false,
      credentials: args.credentials,
      ...args.s3ClientConfig,
    });
  }

  async uploadToS3(bucketName: string, file: File, uploadKey: string) {
    try {
      const data = await this.client.send(
        new PutObjectCommand({
          Bucket: bucketName,
          Key: uploadKey,
          Body: file,
          ContentType: file.type,
        })
      );
      return { data, error: null };
    } catch (error) {
      return { data: null, error: error as string };
    }
  }

  async createPresignedUrl(command: PutObjectCommand, expiresIn: number = 120) {
    return getSignedUrl(this.client, command, { expiresIn });
  }

  async createPresignedPost(
    bucketName: string,
    uploadKey: string,
    expiresIn: number = 120
  ) {
    return createPresignedPost(this.client, {
      Bucket: bucketName,
      Key: uploadKey,
      Expires: expiresIn,
    });
  }
}
