import { S3Service } from './s3.service';
import { AWS_SECRETS } from '../config/secrets';
import { Environments, UploadAssetResult } from '../types';
import { PutObjectCommand } from '@aws-sdk/client-s3';
import {
  SitemateRegions,
  AwsMainRegion,
  Products,
} from '@site-mate/dashpivot-shared-library';

export class AssetUpload {
  file: File;
  sydneyS3Service: S3Service;
  sydneyS3ServiceWithDualStackedEnpoint: S3Service;
  assetsUploadBucketName: string;
  bucketUrl: string;

  constructor(file: File, assetUploadConfig: { env: Environments }) {
    this.file = file;
    this.assetsUploadBucketName = this.getAssetsUploadBucketName(
      assetUploadConfig.env
    );
    this.bucketUrl = this.getBucketUrl(assetUploadConfig.env);
    this.sydneyS3Service = new S3Service({
      region: AwsMainRegion,
      credentials: AWS_SECRETS.credentials,
    });
    this.sydneyS3ServiceWithDualStackedEnpoint = new S3Service({
      region: AwsMainRegion,
      credentials: AWS_SECRETS.credentials,
      s3ClientConfig: { useDualstackEndpoint: true },
    });
  }

  private getAssetsUploadBucketName(env: Environments): string {
    const sitemateRegion = SitemateRegions[AwsMainRegion];
    return `${Products.Dashpivot.toLowerCase()}-cn-test-bucket-${env}-${sitemateRegion}`;
  }

  private getBucketUrl(env: Environments): string {
    switch (env) {
      case 'production':
        return 'https://uploads.dashpivot.com';
      case 'development':
        return 'https://uploads-dev.dashpivot.com';
      default:
        return `https://uploads-${env}.dashpivot.com`;
    }
  }

  async uploadFile(): Promise<UploadAssetResult[]> {
    const keyPrefix = `photo-test-app/${new Date().toISOString()}`;
    const promises = [
      this.uploadWithoutPresignedUrl(keyPrefix),
      this.uploadWithPresignedUrl(keyPrefix),
      this.uploadWithPresignedUrlUsingDomainAlias(keyPrefix),
      this.uploadWithPresignedAndDualStackedEndpointToNewBucket(keyPrefix),
    ];
    return Promise.all(promises);
  }

  private async uploadWithPresignedUrl(
    keyPrefix: string
  ): Promise<UploadAssetResult> {
    const testName = 'Presigned-URL - Upload to S3 AU';
    const uploadKey = `${keyPrefix}/${testName}-${this.file.name}`;

    try {
      const command = new PutObjectCommand({
        Bucket: this.assetsUploadBucketName,
        Key: uploadKey,
        ContentType: this.file.type,
      });

      const presignedUrl = await this.sydneyS3Service.createPresignedUrl(
        command
      );
      const response = await this.uploadToS3ViaPresignedUrl(presignedUrl);

      return {
        testName,
        data: response.ok ? response : null,
        error: !response.ok ? JSON.stringify(await response.json()) : null,
      };
    } catch (error) {
      return { testName, data: null, error: String(error) };
    }
  }

  private async uploadWithoutPresignedUrl(
    keyPrefix: string
  ): Promise<UploadAssetResult> {
    const testName = 'No Presigned-URL - Upload to S3 AU';
    const uploadKey = `${keyPrefix}/${testName}-${this.file.name}`;

    try {
      const result = await this.sydneyS3Service.uploadToS3(
        this.assetsUploadBucketName,
        this.file,
        uploadKey
      );

      return { testName, ...result };
    } catch (error) {
      return { testName, data: null, error: String(error) };
    }
  }

  private async uploadWithPresignedUrlUsingDomainAlias(
    keyPrefix: string
  ): Promise<UploadAssetResult> {
    const testName = 'Presigned-URL - Upload to different domain';
    const uploadKey = `${keyPrefix}/${testName}-${this.file.name}`;

    try {
      const { fields } = await this.sydneyS3Service.createPresignedPost(
        this.assetsUploadBucketName,
        uploadKey
      );

      const formData = new FormData();
      Object.entries(fields).forEach(([field, value]) => {
        formData.append(field, value);
      });
      formData.append('file', this.file);

      const response = await fetch(this.bucketUrl, {
        method: 'POST',
        body: formData,
      });

      return {
        testName,
        data: response,
        error: response.ok ? null : 'Failed to upload with presigned URL',
      };
    } catch (error) {
      console.error('Failed to create pre signed URL:', error);
      return { testName, data: null, error: String(error) };
    }
  }

  private async uploadWithPresignedAndDualStackedEndpointToNewBucket(
    keyPrefix: string
  ): Promise<UploadAssetResult> {
    const testName = 'Presigned-URL - Upload to AU Dual Stacked';
    const uploadKey = `${keyPrefix}/${testName}-${this.file.name}`;

    try {
      const command = new PutObjectCommand({
        Bucket: this.assetsUploadBucketName,
        Key: uploadKey,
        ContentType: this.file.type,
      });

      const presignedUrl =
        await this.sydneyS3ServiceWithDualStackedEnpoint.createPresignedUrl(
          command
        );
      const response = await this.uploadToS3ViaPresignedUrl(presignedUrl);

      return {
        testName,
        data: response.ok ? response : null,
        error: !response.ok ? JSON.stringify(await response.json()) : null,
      };
    } catch (error) {
      return { testName, data: null, error: String(error) };
    }
  }

  private async uploadToS3ViaPresignedUrl(url: string): Promise<Response> {
    return fetch(url, {
      method: 'PUT',
      body: this.file,
      headers: {
        'Content-Type': this.file.type,
      },
    });
  }
}
